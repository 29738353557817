<template>
  <div v-if="$mq === 'sm'">
    <div class="sorry">
      <p>Sorry, you cannot use the course builder on mobile. Please log in on your computer to build courses.</p>
      <div class="primary-btn" @click="router.push('/')">Back</div>
    </div>
  </div>

  <div v-else-if="mode === 'Edit'" class="createToolkit" :class="{ moduleMode: editMode === 'module' }">
    <Loading :active.sync="visible"></Loading>
    <div class="leftPanel">
      <div class="name" :class="{ active: editMode === 'toolkit' }" @click="editToolkit">
        <span>{{ course.name }}</span>
      </div>
      <hr class="short" />
      <div v-for="(m, moduleIndex) in course.modules" :key="moduleIndex" class="modules">
        <div
          class="module"
          @click="editModule(moduleIndex)"
          :class="{ active: editMode === 'module' && moduleBeingEdited === moduleIndex }"
        >
          <span>{{ m.name }}</span>
        </div>

        <div
          v-if="(editMode === 'module' || editMode === 'section') && moduleIndex === moduleBeingEdited"
          class="sections"
        >
          <div
            class="section"
            v-for="(section, sectionIndex) in course.modules[moduleBeingEdited].sections"
            :key="sectionIndex"
            @click="editsection(sectionIndex)"
            :class="{
              active:
                editMode === 'section' && moduleBeingEdited === moduleIndex && sectionBeingEdited === sectionIndex,
            }"
          >
            <span>{{ section.name }}</span>
          </div>
          <div class="add" @click="addSection">+ Add Section</div>
        </div>
      </div>
      <div class="add" @click="addModule">+ Add Module</div>
      <hr />
      <div class="hidden"></div>
      <div class="sub-nav">
        <div class="progress">
          <div class="progress-inner" :class="{ opacity: progress === 1 }">
            <ProgressIcon v-if="progress === 2" />
            <h1 v-if="progress === 2">Progress Saved</h1>
            <h1 v-else-if="progress === 1">Saving progress...</h1>
          </div>
        </div>
        <div class="primary-btn preview" @click="previewClassroom">
          Live Preview
        </div>
        <div class="primary-btn" :class="{ disabled: !valid }" @click="publishCourse">Publish</div>
        <div class="primary-btn danger" @click="$router.push('/create-course')">Exit</div>
      </div>
    </div>
    <div class="content">
      <div class="content-inner">
        <!-- Edit Toolkit Mode-->
        <div v-if="editMode === 'toolkit'" class="edit-toolkite">
          <h1>Moular Training Toolkit Name*</h1>
          <input type="text" v-model="course.name" placeholder="Modular Training Toolkit Name" maxlength="28" />
          <p class="description">Give this modular training toolkit a meaningful title.</p>
          <h1>Who It's For</h1>
          <div class="chip-container">
            <div class="chip" v-for="(roleChip, i) of course.roles" :key="roleChip.label">
              {{ roleChip.name }}
              <i class="material-icons" @click="deleteRoleChip(i)">
                <XIcon />
              </i>
            </div>
            <input v-model="currentRoleInput" @keypress.enter="saveRoleChip" @keydown.delete="backspaceRoleDelete" />
          </div>
          <p class="description">
            Indicate which job role(s) this course is designed for. Anyone in any job role will still be able to view
            and take the course.
          </p>
          <h1>Instructor(s)*</h1>
          <!-- <input type="text" v-model="instructors" placeholder="Instructor(s)" /> -->
          <div class="chip-container">
            <div class="chip" v-for="(instructorChip, i) of instructorNames" :key="instructorChip.label">
              {{ instructorChip }}
              <i class="material-icons" @click="deleteInstructorChip(i)">
                <XIcon />
              </i>
            </div>
            <input
              v-model="currentInstructorInput"
              @keypress.enter="saveInstructorChip"
              @keydown.delete="backspaceInstructorDelete"
            />
          </div>
          <p class="description">
            Assign instructor(s) to this training using their Hub ID. This course will then appear in their ILT Toolbox
            dashboard.
          </p>
          <!-- <h1>Date</h1>
          <div class="date twoCol">
            <div class="left">
              <CalendarSVG class="calendar" />
              <Datepicker v-model="date" :format="'MM/dd/yy'" :use-utc="true"></Datepicker>
            </div>
          </div>
          <p class="description">
            Set a date for this course.
          </p> -->

          <div class="hr"></div>

          <div class="twoCol">
            <div class="left course">
              <h1>Course</h1>
              <input
                :class="{ notUnique: !uniqueCourseNum && course.courseNumber }"
                type="number"
                v-model="course.courseNumber"
                placeholder="Course"
              />
              <p class="description" v-if="!course.courseNumber || uniqueCourseNum">
                Assign a unique number to this course.
              </p>
              <p class="description invalid" v-else>That number is already taken.</p>
            </div>
            <div class="prerequisites wide">
              <h1>Recommended Prerequisites</h1>
              <!-- <input type="text" v-model="prerequisites" placeholder="Recommended Prerequisites" /> -->
              <div class="chip-container">
                <div class="chip" v-for="(prereqChip, i) of course.prerequisites" :key="prereqChip.label">
                  {{ prereqChip }}
                  <i class="material-icons" @click="deletePrereqChip(i)">
                    <XIcon />
                  </i>
                </div>
                <input
                  type="text"
                  v-model="currentPrereqInput"
                  @keypress.enter="savePrereqChip"
                  @keydown.delete="backspacePrereqDelete"
                />
              </div>
              <p class="description">Indicate any courses a user should take before this one.</p>
            </div>
          </div>

          <!-- <div class="hr"></div> -->

          <!-- <div class="twoCol">
            <div class="link full">
              <h1>Adobe Connect Link*</h1>
              <input type="text" v-model="course.livestreamLink" placeholder="Adobe Connect Link" />
              <p class="description">Enter the URL the instructor will use for the live stream.</p>
            </div>
          </div> -->
          <!-- <div class="twoCol">
            <div class="left adobePin">
              <h1>Adobe Connect PIN*</h1>
              <input type="text" v-model="course.livestreamPin" placeholder="Adobe Connect PIN" />
              <p class="description"></p>
            </div>
            <div class="link wide">
              <h1>Dial-In Number*</h1>
              <input type="text" v-model="course.livestreamPhone" placeholder="Dial-In Number" />
              <p class="description"></p>
            </div>
          </div> -->

          <div class="hr"></div>

          <div class="checklists">
            <div class="row">
              <h1>Required Equipment</h1>
              <ul>
                <li v-for="(equipment, index) in requiredEquipment" :key="index">
                  <Checkbox :label="equipment.name" v-model="equipment.checked" />
                </li>
              </ul>
            </div>
            <div class="row last">
              <h1>Preferred Equipment</h1>
              <ul v-if="preferredEquipment.length">
                <li v-for="(equipment, index) in preferredEquipment" :key="index">
                  <Checkbox :label="equipment.name" v-model="equipment.checked" @clicked="makePreferred($event)" />
                </li>
              </ul>
              <p v-else class="description">
                You must select at least one option in Required Equipment in order to select options in Preferred
                Equipment
              </p>
            </div>
          </div>

          <div class="hr"></div>

          <h1>Course Description*</h1>
          <div class="wysiwyg">
            <ckeditor v-model="course.description" :config="editorConfig"></ckeditor>
          </div>
          <p class="description">Describe this course in as much detail as possible.</p>
        </div>

        <!-- Edit Module Mode-->
        <div v-else-if="editMode === 'module'" class="edit-module">
          <h1>Module Name*</h1>
          <input
            type="text"
            v-model="course.modules[moduleBeingEdited].name"
            placeholder="Module Name"
            maxlength="28"
          />
          <p class="description">Give this section a meaningful title.</p>
          <h1>Module Description*</h1>
          <div class="wysiwyg">
            <ckeditor v-model="course.modules[moduleBeingEdited].description" :config="editorConfig"></ckeditor>
          </div>
          <p class="description">Describe this course in as much detail as possible.</p>
          <div
            v-if="course.modules && course.modules.length > 1"
            class="removeModule"
            @click="removeModule(moduleBeingEdited)"
          >
            <RedXIcon />
            <div class="text">Remove Module</div>
          </div>
        </div>

        <!-- Edit Section Mode -->
        <div v-else-if="editMode === 'section'" class="edit-section">
          <h1>Section Name*</h1>
          <input
            type="text"
            v-model="course.modules[moduleBeingEdited].sections[sectionBeingEdited].name"
            maxlength="28"
            placeholder="Section Name"
          />
          <p class="description">Give this section a meaningful title.</p>
          <modal
            v-if="course.modules[moduleBeingEdited].sections[sectionBeingEdited].assets.length"
            name="delete-asset-modal"
          >
            <h1 class="modal-header">Are you sure you wish to delete this asset?</h1>
            <h2 class="red">
              If you wish to delete “{{
                course.modules[moduleBeingEdited].sections[sectionBeingEdited].assets[assetBeingDeleted].type
              }}
              |
              {{
                course.modules[moduleBeingEdited].sections[sectionBeingEdited].assets[assetBeingDeleted].title ||
                'No Title'
              }}” please type DELETE in the box below and click Yes, Delete. This cannot be undone.
            </h2>
            <input type="text" v-model="del" class="delete" placeholder="Type DELETE" />
            <div class="primary-btn" @click="checkDelete">Yes, Delete</div>
            <div class="primary-btn secondary-btn" @click="hideModal">No, Cancel</div>
          </modal>
          <!-- Asset Tabs-->
          <draggable
            v-model="course.modules[moduleBeingEdited].sections[sectionBeingEdited].assets"
            group="assets"
            @start="drag = true"
            @end="drag = false"
          >
            <SectionAsset
              v-for="(assetTab, assetIndex) in course.modules[moduleBeingEdited].sections[sectionBeingEdited].assets"
              :key="assetIndex"
              :asset="assetTab"
              :courseID="courseBeingEdited.id"
              :moduleBeingEdited="moduleBeingEdited"
              :sectionBeingEdited="sectionBeingEdited"
              :assetIndex="assetIndex"
              @removeAsset="showDeleteAssetModal($event)"
              @saveProgress="saveProgress"
            />
          </draggable>

          <h1>Section Builder*</h1>
          <div class="builder-assets">
            <div
              v-for="(asset, index) in builderAssets"
              :key="index"
              class="asset"
              draggable="true"
              @dragstart="startDrag(index)"
              @dragend="endDrag"
            >
              {{ asset.name }}
            </div>
          </div>
          <!-- Dropzone -->
          <div class="dropzone" v-on:drop="drop" @dragover="allowDrop" @dragleave="leaveDrag">
            Drag and drop elements here
          </div>
          <div class="hr" v-if="course.modules[moduleBeingEdited].sections.length > 1"></div>
          <div
            v-if="course.modules[moduleBeingEdited].sections.length > 1"
            class="removeSection"
            @click="removeSection(moduleBeingEdited, sectionBeingEdited)"
          >
            <RedXIcon />
            <div class="text">Remove Section</div>
          </div>
        </div>
      </div>
      <div class="hidden"></div>
      <footer class="footer">© 2020 Volkswagen Academy</footer>
    </div>
  </div>

  <!-- Preview -->
  <div v-else-if="mode === 'Preview'" class="previewClassroom">
    <router-view></router-view>
  </div>
</template>

<script>
// import Datepicker from 'vuejs-datepicker';
import XIcon from '@/assets/icons/x.svg';
import Checkbox from '@/components/Checkbox.vue';
import ProgressIcon from '@/assets/icons/progress_saved.svg';
import RedXIcon from '@/assets/icons/x_red.svg';
import SectionAsset from '@/components/SectionAsset.vue';
import draggable from 'vuedraggable';
import Loading from 'vue-loading-overlay';
import VModal from 'vue-js-modal';
// import CalendarSVG from '@/assets/icons/calendar.svg';
import axios from 'axios';
import vueDebounce from 'vue-debounce';
import { mapGetters } from 'vuex';

import Vue from 'vue';
import CKEditor from 'ckeditor4-vue/dist/legacy';

Vue.use(VModal);
Vue.use(CKEditor);
Vue.use(vueDebounce);

export default {
  components: {
    // Datepicker,
    XIcon,
    Checkbox,
    ProgressIcon,
    RedXIcon,
    SectionAsset,
    draggable,
    Loading,
    // CalendarSVG,
  },
  updated() {
    // reorder assets on drag
    if (this.moduleBeingEdited > -1 && this.sectionBeingEdited > -1) {
      let x = 0;
      this.course.modules[this.moduleBeingEdited].sections[this.sectionBeingEdited].assets.forEach((asset) => {
        x += 1;
        asset.sequence = x.toString();
      });
    }
    // console.log('updated');
    // this.saveProgress();
  },
  created() {
    this.preferredEquipmentStatus = this.requiredEquipment.map((equipment) => ({ ...equipment }));
    // const d = new Date();
    // this.date = d;
  },
  mounted() {
    this.initCourse();
  },
  methods: {
    initCourse() {
      this.visible = true;
      axios
        .post(
          `${process.env.VUE_APP_API_URL}/courses`,
          {
            name: this.course.name,
            roles: this.course.roles,
            instructors: this.course.instructors,
            prereqs: this.course.prerequisites,
            equipment: this.allEquipment,
            courseNumber: this.course.courseNumber,
            livestreamService: this.course.livestreamService,
            livestreamLink: this.course.livestreamLink,
            livestreamPin: this.course.livestreamPin,
            livestreamPhone: this.course.livestreamPhone,
            description: this.course.description,
            // startDate: this.utcDate,
            modules: this.course.modules,
            status: this.course.status,
          },
          {
            headers: { authorization: sessionStorage.getItem('vwcup-token') },
          },
        )
        .then((response) => {
          this.uniqueCourseID = response.data.data.courseID;
          axios
            .get(`${process.env.VUE_APP_API_URL}/courses/${this.uniqueCourseID}`, {
              headers: { authorization: sessionStorage.getItem('vwcup-token') },
            })
            .then((response2) => {
              const x = response2.data.data.course;
              this.$store.dispatch('setCourseBeingEdited', x);
              this.$router.replace(`/edit-course/${this.uniqueCourseID}`);
            })
            .catch((error) => {
              // handle error
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
          this.visible = false;
        });
    },
    // instructor chips
    saveInstructorChip() {
      const { instructors, currentInstructorInput, set } = this;
      const y = this.currentInstructorInput.toUpperCase();
      const x = this.instructors.findIndex((ins) => `${ins.firstName} ${ins.lastName}`.toUpperCase() === y);
      const z = this.instructorNames.findIndex((ins) => ins.toUpperCase() === y);
      if (x > -1 && z === -1) {
        // eslint-disable-next-line no-unused-expressions
        ((set && instructors.indexOf(currentInstructorInput) === -1) || !set) &&
          this.course.instructors.push(this.instructors[x].id);
        this.instructorNames.push(`${this.instructors[x].firstName} ${this.instructors[x].lastName}`);
      }
      this.currentInstructorInput = '';
      this.saveProgress();
    },
    deleteInstructorChip(index) {
      this.course.instructors.splice(index, 1);
      this.instructorNames.splice(this.instructorNames.length - 1);
      this.saveProgress();
    },
    backspaceInstructorDelete({ which }) {
      // eslint-disable-next-line no-unused-expressions
      which === 8 &&
        this.currentInstructorInput === '' &&
        this.course.instructors.splice(this.course.instructors.length - 1) &&
        this.instructorNames.splice(this.instructorNames.length - 1);
      this.saveProgress();
    },
    // prereq chips
    // prereq chips
    savePrereqChip() {
      const { courses, currentPrereqInput, set } = this;
      // const y = this.currentPrereqInput;
      // const x = this.courses.findIndex((course) => `${course.courseNumber}` === y);
      // const z = this.prereqs.findIndex((course) => course === this.courses[x].name);
      // if (x > -1 && z === -1) {
      // eslint-disable-next-line no-unused-expressions
      ((set && courses.indexOf(currentPrereqInput) === -1) || !set) &&
        this.course.prerequisites.push(this.currentPrereqInput);
      //   this.prereqs.push(`${this.courses[x].name}`);
      // }
      this.currentPrereqInput = '';
      this.saveProgress();
    },
    deletePrereqChip(index) {
      this.course.prerequisites.splice(index, 1);
      this.prereqs.splice(index, 1);
      this.saveProgress();
    },
    backspacePrereqDelete({ which }) {
      // eslint-disable-next-line no-unused-expressions
      which === 8 &&
        this.currentPrereqInput === '' &&
        this.course.prerequisites.splice(this.course.prerequisites.length - 1) &&
        this.repreqs.splice(this.prereqs.length - 1);
      this.saveProgress();
    },
    // role chips
    saveRoleChip() {
      const { roles, currentRoleInput, set } = this;
      const y = this.currentRoleInput.toUpperCase();
      const x = this.jobRoles.findIndex((role) => role.name.toUpperCase() === y);
      const z = this.course.roles.findIndex((role) => role.name.toUpperCase() === y);
      if (x > -1 && z === -1) {
        // eslint-disable-next-line no-unused-expressions
        ((set && roles.indexOf(currentRoleInput) === -1) || !set) &&
          this.course.roles.push({
            code: this.jobRoles[x].code,
            name: this.jobRoles[x].name,
          });
      }
      this.currentRoleInput = '';
      this.saveProgress();
    },
    deleteRoleChip(index) {
      this.course.roles.splice(index, 1);
      this.saveProgress();
    },
    backspaceRoleDelete({ which }) {
      // eslint-disable-next-line no-unused-expressions
      which === 8 && this.currentRoleInput === '' && this.course.roles.splice(this.course.roles.length - 1);
      this.saveProgress();
    },
    editModule(index) {
      this.editMode = 'module';
      this.moduleBeingEdited = index;
    },
    editsection(index) {
      this.editMode = 'section';
      this.sectionBeingEdited = index;
    },
    editToolkit() {
      this.editMode = 'toolkit';
      this.moduleBeingEdited = -1;
    },
    removeModule(index) {
      const x = [];
      let y = -1;
      this.course.modules.forEach((mod) => {
        y += 1;
        if (y !== index) {
          x.push(mod);
        }
      });
      this.course.modules = x;
      // reorder
      y = 0;
      this.course.modules.forEach((mod) => {
        y += 1;
        mod.sequence = y.toString();
      });
      this.moduleBeingEdited = 0;
      this.saveProgress();
    },
    removeSection(moduleIndex, sectionIndex) {
      const x = [];
      let y = -1;
      this.course.modules[moduleIndex].sections.forEach((section) => {
        y += 1;
        if (y !== sectionIndex) {
          x.push(section);
        }
      });
      this.course.modules[moduleIndex].sections = x;
      // reorder
      y = 0;
      this.course.modules[moduleIndex].sections.forEach((sec) => {
        y += 1;
        sec.sequence = y.toString();
      });
      this.sectionBeingEdited = 0;
      this.saveProgress();
    },
    removeAsset(moduleIndex, sectionIndex, assetIndex) {
      const x = [];
      let y = -1;
      this.course.modules[moduleIndex].sections[sectionIndex].assets.forEach((asset) => {
        y += 1;
        if (y !== assetIndex) {
          x.push(asset);
        }
      });
      this.course.modules[moduleIndex].sections[sectionIndex].assets = x;
      // reorder
      y = 0;
      this.course.modules[moduleIndex].sections[sectionIndex].assets.forEach((asset) => {
        y += 1;
        asset.sequence = y.toString();
      });
      this.saveProgress();
    },
    addModule() {
      this.course.modules.push({
        name: `Module ${this.course.modules.length + 1}`,
        sequence: `${this.course.modules.length + 1}`,
        description: '',
        sections: [
          {
            name: 'Section 1.1',
            sequence: '1',
            assets: [],
          },
        ],
      });
      this.editMode = 'module';
      this.moduleBeingEdited = this.course.modules.length - 1;
      this.saveProgress();
    },
    addSection() {
      this.course.modules[this.moduleBeingEdited].sections.push({
        name: 'New Section',
        sequence: `${this.course.modules[this.moduleBeingEdited].sections.length + 1}`,
        assets: [],
      });
      this.editMode = 'section';
      this.sectionBeingEdited = this.course.modules[this.moduleBeingEdited].sections.length - 1;
      this.saveProgress();
    },
    startDrag(index) {
      let x = -1;
      this.builderAssets.forEach((asset) => {
        x += 1;
        if (x === index) {
          this.assetBeingDragged = asset.name;
        }
      });
      const assets = document.getElementsByClassName('asset');
      assets[index].classList.add('dragging');
    },
    endDrag() {
      // remove drag style
      const dragging = document.getElementsByClassName('dragging');
      dragging.forEach((el) => {
        el.classList.remove('dragging');
      });
    },
    allowDrop(event) {
      event.preventDefault();
      const dropzone = document.getElementsByClassName('dropzone');
      dropzone.forEach((zone) => {
        zone.classList.add('droppable-hover');
      });
    },
    leaveDrag() {
      const dropzone = document.getElementsByClassName('droppable-hover');
      dropzone.forEach((zone) => {
        zone.classList.remove('droppable-hover');
      });
    },
    drop() {
      this.addAsset(this.assetBeingDragged);
      const dropzone = document.getElementsByClassName('droppable-hover');
      dropzone.forEach((zone) => {
        zone.classList.remove('droppable-hover');
      });
    },
    addAsset(asset) {
      this.course.modules[this.moduleBeingEdited].sections[this.sectionBeingEdited].assets.push({
        title: '',
        sequence: `${this.course.modules[this.moduleBeingEdited].sections[this.sectionBeingEdited].assets.length + 1}`,
        type: asset,
        assetURL: '',
        participantDescription: '',
        instructorDescription: '',
        filePath: '',
        fileName: '',
        thumbnail: '',
      });
      this.saveProgress();
    },
    publishCourse() {
      this.visible = true;
      axios
        .put(
          `${process.env.VUE_APP_API_URL}/courses/${this.uniqueCourseID}`,
          {
            name: this.course.name,
            roles: this.course.roles,
            instructors: this.course.instructors,
            prereqs: this.course.prerequisites,
            equipment: this.allEquipment,
            courseNumber: this.course.courseNumber,
            livestreamService: this.course.livestreamService,
            livestreamLink: this.course.livestreamLink,
            livestreamPin: this.course.livestreamPin,
            livestreamPhone: this.course.livestreamPhone,
            description: this.course.description,
            // startDate: this.utcDate,
            modules: this.course.modules,
            status: 'published',
          },
          {
            headers: { authorization: sessionStorage.getItem('vwcup-token') },
          },
        )
        .then(() => {
          this.visible = false;
          this.$router.push('/all-courses');
        })
        .catch((error) => {
          console.error(error);
          this.visible = false;
        });
    },
    previewClassroom() {
      let x = {};
      axios
        .get(`${process.env.VUE_APP_API_URL}/courses/${this.uniqueCourseID}`, {
          headers: { authorization: sessionStorage.getItem('vwcup-token') },
        })
        .then((response) => {
          x = response.data.data.course;
          this.$store.dispatch('setActiveCourse', x);
          this.$store.dispatch('setActiveCourseEquipment', this.allEquipment);
          // this.mode = 'Preview';
          // this.$router.push('/preview');
          window.open('/preview', '_blank');
        })
        .catch((error) => {
          // handle error
          console.error(error);
        });
    },
    saveProgress() {
      this.progress = 1;
      const saveCourse = this.course;

      const roles = [];
      saveCourse.roles.forEach((role) => {
        roles.push({ code: role.roleID, name: role.name });
      });
      const instructors = [];
      saveCourse.instructors.forEach((instructor) => {
        instructors.push(instructor.userID);
      });
      const prereqs = [];
      if (saveCourse.prereqs) {
        saveCourse.prereqs.forEach((prereq) => {
          prereqs.push(prereq.prereqID);
        });
      } else if (saveCourse.prerequisites) {
        saveCourse.prerequisites.forEach((prereq) => {
          prereqs.push(prereq.prereqID);
        });
      }
      const equipment = [];
      saveCourse.equipment.forEach((equ) => {
        equipment.push({ name: equ.name, required: equ.required });
      });
      // this.visible = true;
      axios
        .put(
          `${process.env.VUE_APP_API_URL}/courses/${this.uniqueCourseID}`,
          {
            name: this.course.name,
            roles: this.course.roles,
            instructors: this.course.instructors,
            prereqs: this.course.prerequisites,
            equipment: this.allEquipment,
            courseNumber: this.course.courseNumber,
            livestreamService: this.course.livestreamService,
            livestreamLink: this.course.livestreamLink,
            livestreamPin: this.course.livestreamPin,
            livestreamPhone: this.course.livestreamPhone,
            description: this.course.description,
            // startDate: this.utcDate,
            modules: this.course.modules,
            status: this.course.status,
          },
          {
            headers: { authorization: sessionStorage.getItem('vwcup-token') },
          },
        )
        .then(() => {
          this.progressSaved = true;
          this.progress = 2;
          // wait 3s turn false

          // this.visible = false;
          // this.$router.push('/all-courses');
        })
        .catch((error) => {
          console.error(error);
          this.process = 0;
          this.visible = false;
        });
    },
    showDeleteAssetModal(assetSequence) {
      this.assetBeingDeleted = assetSequence - 1;
      this.$modal.show('delete-asset-modal');
    },
    hideModal() {
      this.$modal.hide('delete-asset-modal');
      this.del = '';
    },
    checkDelete() {
      if (this.del.toUpperCase() === 'DELETE') {
        this.removeAsset(this.moduleBeingEdited, this.sectionBeingEdited, this.assetBeingDeleted);
        this.assetBeingDeleted = 0;
      }
      this.hideModal();
    },
  },
  watch: {
    course: {
      handler() {
        // if (this.course.date === '0000-00-00 00:00:00' || !this.course.date) {
        //   this.course.date = new Date().toISOString();
        // }
        this.saveProgress();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['jobRoles', 'instructors', 'courses']),
    // utcDate() {
    //   if (this.course.date) {
    //     const x = new Date(this.course.date).toISOString();
    //     return x;
    //   }
    //   const x = new Date().toISOString();
    //   return x;
    // },
    valid() {
      if (
        this.course &&
        this.course.name &&
        // this.course.courseNumber &&
        // this.uniqueCourseNum &&
        // this.course.date &&
        // this.course.roles &&
        this.course.instructors.length &&
        this.course.description
      ) {
        return true;
      }
      return false;
    },
    allEquipment() {
      const x = [];
      this.requiredEquipment.forEach((eq) => {
        if (eq.checked) {
          x.push({
            name: eq.name,
            required: 'YES',
          });
        }
      });
      this.preferredEquipment.forEach((eq) => {
        if (eq.checked) {
          const y = x.findIndex((xeq) => xeq.name === eq.name);
          if (y >= 0) {
            x[y].required = 'NO';
          } else {
            x.push({
              name: eq.name,
              required: 'NO',
            });
          }
        }
      });
      return x;
    },
    uniqueCourseNum() {
      let x = true;
      if (this.course.courseNumber && this.courses) {
        x = true;
        this.courses.forEach((course) => {
          if (this.course.courseNumber.toString() === course.courseNumber.toString()) {
            x = false;
          }
        });
      }
      return x;
    },
    preferredEquipment() {
      // const x = [];
      // this.requiredEquipment.forEach((eq) => {
      //   if (eq.checked) {
      //     x.push({ name: eq.name, checked: false });
      //   }
      // });
      // return x;
      return this.preferredEquipmentStatus.filter((equipment, index) => this.requiredEquipment[index].checked);
    },
    // numericPrereqs() {
    //   const x = [];
    //   this.course.prerequisites.forEach((prereq) => {
    //     x.push(parseInt(prereq, 10));
    //   });
    //   return x;
    // },
  },
  data() {
    return {
      progress: 0,
      assetBeingDeleted: 0,
      del: '',
      progressSaved: false,
      mode: 'Edit',
      course: {
        name: 'New Modular Training Toolkit',
        roles: [],
        instructors: [],
        equipment: [],
        // date: '',
        courseNumber: '',
        prerequisites: [],
        livestreamService: '',
        livestreamLink: '',
        livestreamPin: '',
        livestreamPhone: '',
        description: '',
        modules: [
          {
            name: 'Module 1',
            sequence: '1',
            description: '',
            sections: [
              {
                name: 'Section 1.1',
                sequence: '1',
                assets: [],
              },
            ],
          },
        ],
        status: 'draft',
      },
      uniqueCourseID: null,
      // prereqs: [],
      instructorNames: [],
      visible: false,
      // date: '',
      // wysiwyg config
      editorConfig: {
        toolbarGroups: [
          { name: 'clipboard', groups: ['undo'] },
          { name: 'styles', groups: ['styles'] },
          { name: 'basicstyles', groups: ['bold, italic'] },
          {
            name: 'paragraph',
            groups: ['list', 'indent', 'align'],
          },
        ],
        removeButtons: 'Cut,Copy,Paste,Underline,Strike,Subscript,Superscript,PasteFromWord,PasteText,Styles',
        removePlugins: 'PasteFromWord,',
        pasteAsPlainText: false,
      },
      //
      editMode: 'toolkit',
      moduleBeingEdited: -1,
      sectionBeingEdited: -1,
      currentInstructorInput: '',
      currentPrereqInput: '',
      currentRoleInput: '',
      requiredEquipment: [
        {
          name: 'iPad',
          checked: false,
        },
        {
          name: 'Laptop computer',
          checked: false,
        },
        {
          name: 'Webcam',
          checked: false,
        },
        {
          name: 'Lorem Ipsum',
          checked: false,
        },
        {
          name: 'Desktop computer',
          checked: false,
        },
        {
          name: 'Mobile phone',
          checked: false,
        },
        {
          name: 'Lorem ipsum',
          checked: false,
        },
        {
          name: 'Lorem ipsum',
          checked: false,
        },
      ],
      // preferredEquipment: [
      //   {
      //     name: 'Desktop computer',
      //     checked: false,
      //   },
      //   {
      //     name: 'Mobile phone',
      //     checked: false,
      //   },
      //   {
      //     name: 'Lorem ipsum',
      //     checked: false,
      //   },
      //   {
      //     name: 'Lorem ipsum',
      //     checked: false,
      //   },
      // ],
      preferredEquipmentStatus: [],
      builderAssets: [
        {
          name: 'Web-Based Training',
        },
        {
          name: 'PDF',
        },
        {
          name: 'Image',
        },
        {
          name: 'Video',
        },
        {
          name: 'Slideshow',
        },
        {
          name: 'Link',
        },
        {
          name: 'Text',
        },
        {
          name: 'Divider',
        },
      ],
      assetBeingDragged: '',
    };
  },
};
</script>

<style lang="scss" scoped>
.createToolkit {
  .wysiwyg {
    width: calc(100% + 30px);
    margin-bottom: 5px;
    &.hide {
      display: none;
    }
  }
  display: flex;
  > .leftPanel {
    width: 23%;
    background: $primary-white;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.29);
    min-height: calc(100vh - 101px);
    display: flex;
    flex-direction: column;
    > .name {
      width: 100%;
      min-height: 57px;
      display: flex;
      align-items: center;
      cursor: pointer;
      &.active {
        background: rgba(0, 176, 240, 0.4);
      }
      > span {
        font-family: $head-font-stack;
        font-weight: 700;
        font-size: 1.4rem;
        padding-left: 20px;
        width: calc(100% - 40px);
        word-wrap: break-word;
      }
    }
    > .modules {
      > .module {
        font-family: $head-font-stack;
        font-weight: 300;
        font-size: 1.2rem;
        cursor: pointer;
        min-height: 35px;
        display: flex;
        align-items: center;
        &.active {
          background: rgba(0, 176, 240, 0.4);
          font-weight: 700;
        }
        > span {
          padding-left: 20px;
          width: calc(100% - 40px);
          word-wrap: break-word;
        }
      }
      > .sections {
        > .section {
          font-family: $head-font-stack;
          font-weight: 300;
          font-size: 1.2rem;
          padding: 0 20px 0 32px;
          cursor: pointer;
          min-height: 35px;
          display: flex;
          align-items: center;
          &.active {
            background: rgba(0, 176, 240, 0.4);
            font-weight: 700;
          }
          > span {
            padding-left: 20px;
            width: calc(100% - 40px);
            word-wrap: break-word;
          }
        }
        > .add {
          height: 35px;
          line-height: 35px;
          color: $primary-light;
          font-size: 1.2rem;
          font-family: $head-font-stack;
          font-weight: 700;
          padding: 0 20px 0 52px;
          cursor: pointer;
        }
      }
    }
    > .add {
      height: 35px;
      line-height: 35px;
      color: $primary-light;
      font-size: 1.2rem;
      font-family: $head-font-stack;
      font-weight: 700;
      padding: 0 20px;
      cursor: pointer;
    }
    > .hidden {
      flex: auto;
    }
    > .sub-nav {
      justify-self: end;
      margin-bottom: 20px;
      > .progress {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 15px auto;
        > .progress-inner {
          display: flex;
          align-items: center;
          > svg {
            width: 21px;
            height: 21px;
            margin-right: 10px;
          }
          > h1 {
            margin: 0;
            font-size: 1.6rem;
            font-weight: 300;
          }
          &.opacity {
            opacity: 0.5;
          }
        }
      }
      > .primary-btn {
        text-align: center;
        width: 136px;
        margin: 0 auto;
        margin-bottom: 10px;
        padding-left: 0;
        padding-right: 0;
        &.danger {
          background: $red;
          color: $primary-white;
        }
        &.disabled {
          pointer-events: none;
          background: $light-grey;
          border-color: $light-grey;
          color: #707070;
        }
        &.preview {
          border: 1px solid $primary-dark;
          background: $primary-white;
        }
      }
    }
    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 1px solid $light-grey;
      margin: 25px 0;
      padding: 0;
      &.short {
        margin-top: 0;
        margin-bottom: 5px;
      }
    }
  }
  > .content {
    display: flex;
    flex-direction: column;
    width: 77%;
    > .content-inner {
      padding: 35px 150px 35px 35px;
      h1 {
        font-family: $head-font-stack;
        font-weight: 700;
        margin-bottom: 5px;
        margin-top: 0px;
        font-size: 1rem;
      }
      input {
        width: 100%;
        margin-bottom: 5px;
        &.notUnique {
          border-color: $danger;
        }
      }
      .description {
        font-weight: 300;
        font-size: 1rem;
        margin-top: 0px;
        margin-bottom: 15px;
        &.invalid {
          color: $danger;
        }
      }
      .hr {
        width: 105%;
        height: 0px;
        border-bottom: 1px solid $light-grey;
        margin: 19px 0 25px 0;
      }
      .chip-container {
        font-size: 1.4rem;
        min-height: 40px;
        width: 100%;
        padding: 0 15px;
        color: #fff;
        outline: none;
        font-weight: 500;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #dfe4e8;
        border-radius: 3px;
        display: flex;
        flex-wrap: wrap;
        align-content: space-between;
        margin-bottom: 5px;
        .chip {
          height: 76%;
          margin: 4px;
          height: 32px;
          background: $primary-light;
          padding: 0px 8px;
          border-radius: 3px;
          display: flex;
          align-items: center;
          i {
            cursor: pointer;
            opacity: 0.56;
            margin-left: 8px;
          }
        }
        input {
          flex: 1 1 auto;
          width: 29px;
          border: none;
          outline: none;
          padding: 4px;
          height: 30px;
          margin-top: 1px;
          margin-bottom: 1px;
        }
        svg {
          height: 10px;
        }
      }
      .checklists {
        ::v-deep {
          label {
            cursor: pointer;
            padding-left: 21px;
            &::before {
              height: 10px;
              width: 10px;
              background-position: center;
              background-size: contain;
            }
          }
        }
        ul {
          margin: 0;
          padding: 20px 0;
          position: relative;
          z-index: 1;
          overflow: hidden;
          display: flex;
          flex-wrap: wrap;
          list-style: none;

          @include lg {
            background: none;
            padding: 0;
          }
          li {
            padding: 0 20px 0 28px;
            color: $primary-black;
            font-family: $head-font-stack;
            font-weight: 300;
            width: 25%;

            @include lg {
              padding: 0;
            }
          }
          .checkbox {
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }
        .last {
          margin-top: 15px;
        }
      }
      .removeModule,
      .removeSection {
        display: flex;
        font-weight: 700;
        color: $danger;
        font-size: 1.4rem;
        align-items: center;
        width: calc(100% + 30px);
        cursor: pointer;
        > svg {
          width: 15px;
          margin: 0 8px 0 auto;
        }
      }
      .builder-assets {
        width: calc(100% + 30px);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        > .asset {
          padding: 9px 15px;
          background: $light-grey;
          font-size: 1.4rem;
          font-family: $head-font-stack;
          color: $primary-dark;
          border-radius: 3px;
          margin-bottom: 5px;
          cursor: pointer;
          &.dragging {
            background: $primary-dark;
            color: $primary-white;
          }
        }
      }
      .dropzone {
        border: 1px dashed $grey;
        border-radius: 3px;
        height: 165px;
        width: calc(100% + 30px);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2rem;
        font-family: $head-font-stack;
        opacity: 0.5;
        &.droppable-hover {
          border-color: $primary-dark;
          background-color: #001e501a;
        }
      }
      > .edit-section {
        .hr {
          width: calc(100% + 30px);
          height: 0px;
          border-bottom: 1px solid $light-grey;
          margin: 15px 0 10px 0;
        }
      }
    }
    .twoCol {
      display: flex;
      .left {
        margin-right: 45px;
      }
      > div {
        width: 27%;
        &.wide {
          width: 73%;
        }
        &.full {
          width: 100%;
        }
      }
    }
    .hidden {
      flex: auto;
    }
  }
}
// datepicker styles
.vdp-datepicker {
  ::v-deep {
    input {
      width: calc(27% + 15px);
    }
    .vdp-datepicker__calendar .cell.day-header {
      font-size: 1.2rem;
    }
    .vdp-datepicker__calendar .cell.selected {
      background: $primary-light;
    }
  }
}
//wysiwig styles
::v-deep {
  .ql-formats {
    margin-right: 0px !important;
  }
  .ql-undo {
    background: url('../assets/icons/undo.svg?external') !important;
    background-size: 10px !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    &:hover {
      background: url('../assets/icons/undo_blue.svg?external') !important;
      background-size: 10px !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
    }
  }
  .ql-redo {
    background: url('../assets/icons/undo.svg?external') !important;
    background-size: 10px !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    transform: scaleX(-1);
    &:hover {
      background: url('../assets/icons/undo_blue.svg?external') !important;
      background-size: 10px !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      transform: scaleX(-1);
    }
  }
  .ql-container {
    min-height: 200px !important;
    margin-bottom: 5px;
    font-family: $text-font-stack;
    font-weight: 300;
    font-size: 1.4rem;
    svg {
      &.hover {
        fill: $primary-light !important;
      }
    }
  }
  .ql-editor {
    height: 200px;
  }
}
.sorry {
  p {
    width: 76%;
    max-width: 315px;
    height: 80vh;
    display: flex;
    align-items: center;
    padding: 0;
    font-weight: 300;
    font-size: 28px;
    width: 80%;
    margin: 0 auto;
  }
  .primary-btn {
    width: 76%;
    max-width: 315px;
    text-align: center;
    margin: 45px auto 0 auto;
  }
}

.date {
  .left {
    padding-right: 20px;
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #dfe4e8;
    border-radius: 3px;
    justify-content: flex-start;
    align-items: center;
    height: 40px;
    ::v-deep {
      input {
        cursor: pointer;
        height: 38px;
        width: 100% !important;
        border: none;
        padding-right: 14px;
        padding-left: 10px;
      }
      .vdp-datepicker__calendar {
        margin-left: -32px;
        @include xl {
          margin-left: -34px;
        }
      }
    }
    .calendar {
      width: 20px;
      padding-left: 14px;
    }
  }
}
::v-deep {
  .vm--modal {
    width: 501px !important;
    height: auto !important;
    text-align: center !important;
    box-shadow: 0px 2px 6px #0000001a !important;
    left: 0 !important;
    margin: auto !important;
    .modal-header {
      margin-top: 35px !important;
      font-size: 2rem !important;
      font-family: $head-font-stack !important;
      font-weight: 300 !important;
      padding: 0 70px !important;
    }
    .red {
      margin-top: 10px !important;
      font-weight: 700 !important;
      color: $danger !important;
      font-size: 1.4rem !important;
      padding: 0 70px !important;
    }
    .delete {
      margin-top: 25px !important;
    }
    input {
      width: 362px !important;
    }
    .primary-btn {
      width: 157px !important;
      margin: 25px auto 0 auto !important;
    }
    .secondary-btn {
      width: 157px !important;
      margin: 5px auto 25px auto !important;
      color: $primary-light !important;
      font-weight: 700 !important;
      width: 157px !important;
      font-size: 1.4rem !important;
      background: $primary-white !important;
    }
  }
}
</style>
